import {defineStore} from "pinia";
import {ref} from "vue";

export const usePaginationStore = defineStore("pagination", ()=>{
    const currentPage = ref(1);
    const scrollPosition = ref(0);

    const actions = {
        getCurrentPage: computed(() => currentPage.value),
        getScrollPosition: computed(() => scrollPosition.value),
        SET_CURRENT_PAGE(page:number):void{
            currentPage.value = page;
        },
        SET_SCROLL_POSITION(position:number):void{
            scrollPosition.value = position;
        }
    }

    return actions;

})